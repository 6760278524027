<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            RESEND DEAL TO LENDERS
            <v-progress-circular
              indeterminate
              class="ml-9"
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  RESEND DEAL TO LENDERS
                  <v-icon class="ml-4">mdi-recycle-variant</v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <p>
                  Below you will find a list of lenders which have already
                  received this deal. By selecting lenders from this list and
                  clicking resend, then they will recieve this deal again. There
                  is also a text entry area where you can add a custom message
                  to the e-mail with a note stating why you are resending this
                  deal to them.
                </p>

                <v-textarea
                  outlined
                  label="Enter note to lenders why it is being re-sent (optional)"
                  v-model="resendReason"
                ></v-textarea>

                <v-row>
                  <v-col>
                    <v-data-table
                      v-model="selectedLenders"
                      :headers="lenderHeaders"
                      :items="lenders"
                      :items-per-page="-1"
                      item-key="id"
                      :loading="tableLoading"
                      show-select
                      class="elevation-1"
                    >
                      <template v-slot:item.lenderType="props">
                        <span style="text-transform: uppercase">{{
                          props.item.lenderType
                        }}</span>
                      </template>
                      <template v-slot:item.lenderName="props">
                        <span style="text-transform: capitalize">{{
                          props.item.lenderName
                        }}</span>
                      </template>
                    </v-data-table>
                    <v-btn class="mt-5" @click="resendDeal"
                      >Resend deal to lenders</v-btn
                    >

                    <v-btn
                      class="mt-5 ml-4"
                      @click="
                        $router.push({ path: `/dashboard/deal/read/${dealId}` })
                      "
                      >Cancel - Back To Deal</v-btn
                    >

                    <v-card v-if="error" class="danger mb-6">
                      <v-card-subtitle class="red--text"
                        >There was an error.</v-card-subtitle
                      >
                      <v-card-text class="red--text">{{ error }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["dealId"],
  data() {
    return {
      componentLoading: true,
      tableLoading: true,
      error: false,
      dealData: {},
      resendReason: '',
      lenders: [],
      selectedLenders: [],
      lenderHeaders: [
        {
          text: "Lender Name",
          align: "start",
          sortable: true,
          value: "lenderName",
        },
        {
          text: "Lender Type",
          align: "start",
          sortable: true,
          value: "lenderType",
        },
      ],
    };
  },
  methods: {
    async resendDeal() {
      try {
        let res = await API().post("/deal/resendDeal", {
          dealId: this.dealData.id,
          lenders: this.selectedLenders,
          resendReason: this.resendReason,
        });

        if (res.status == 201) {
          window.alert('This deal has been successfully resent!')
          this.$router.push(`/dashboard/deal/read/${this.dealData.id}`);
        } else {
          this.error = true;
        }
      } catch (e) {
        console.log(e.response);
      }
    },
  },
  async mounted() {
    try {
      let deal = await API().get(`/deal/read/${this.dealId}`);

      if (deal.status == 200) {
        this.dealData = deal.data.recordset[0];

        let assignedLenders = await API().get(
          `/deal/assignedLenders/${this.dealData.id}`
        );

        console.log(assignedLenders);

        if (assignedLenders.status == 200) {
          this.lenders = assignedLenders.data;

          this.componentLoading = false;
          this.tableLoading = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>


<style>
</style>